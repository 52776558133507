// import { PencilIcon } from "@heroicons/react/solid";
import moment from "moment";
import React from "react";
import { REMOVE_WALLET_FLOW } from "../../constants/ui";
import { middleEllipsis } from "../../helpers/generalHelper";
import useModalNavigation, {
  useModalData,
} from "../../helpers/useModalNavigation";
import TooltipCopy from "../registration/TooltipCopy";
import { useEffect } from "react";
import { useState } from "react";
import { useUser } from "../../helpers/UserProvider";
import { checkIfWalletLinked } from "../../helpers/walletHelpers";

const ExportedWalletList = ({ item }) => {
  const { userData } = useUser();
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(REMOVE_WALLET_FLOW);
  // eslint-disable-next-line no-unused-vars
  const [modalData, setModalData] = useModalData();
  const [walletLinkedToSafleId, setWalletLinkedToSafleId] = useState(false);

  useEffect(() => {
    checkWalletLinkedtoSafleID(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkWalletLinkedtoSafleID = async (item) => {
    const res = await checkIfWalletLinked(item, userData.user.linkedWallets);
    setWalletLinkedToSafleId(res);
    // res;
  };

  const startRemoveWalletFlow = (item) => {
    setModalData({ address: item.address });
    setActiveModalNav("modal_remove_wallet_popup");
  };

  const tooltipText = "";
  const tooltipTextIn = "Copy to clipboard";

  const tooltipTextInChange = "Copied to clipboard";

  const TooltipIconSvg = () => {
    return <span className="icon_copy_gray icon-copy-90"></span>;
  };

  return (
    <>
      <tr>
        <td className="text-left pl-8" width="9%">
          {typeof item?.label === "string"
            ? item?.label?.length > 20
              ? middleEllipsis(
                  item?.label,
                  parseInt(item?.label?.length * 0.1),
                  4
                )
              : item?.label
            : item?.label?.polygon?.length > 20
            ? middleEllipsis(
                item?.label?.polygon,
                parseInt(item?.label?.polygon?.length * 0.1),
                4
              )
            : item?.label?.polygon}
        </td>
        <td className="text-left pl-8" width="20%">
          {middleEllipsis(item?.address)}
        </td>
        <td className="text-center" width="18%">
          {item?.importedOn === "-"
            ? item?.importedOn
            : moment(item?.importedOn).format("DD-MM-YYYY, hh:mm")}
        </td>
        <td className="text-center" width="18%">
          {item?.exportedAt === "-"
            ? item?.exportedAt
            : moment(item?.exportedAt).format("DD-MM-YYYY, hh:mm")}
        </td>
        <td>
          {" "}
          <div className="aoc_table_col  mr-12">
            <span className="filter_table_wallet_controls">
              <span className="filter_table_wallet-icon">
                <span className="ilter_table_wallet-tooltiptext export-pkey ">
                  <TooltipCopy
                    extraClasses="tooltip_registration cursor-pointer tool_tip_vault_management tooltip_webapp_copy"
                    //extraClasses="tooltip_registration cursor-pointer tooltip_seedphrase tooltip_webapp_copy"
                    textToDisplay={tooltipText}
                    imageToDisplay={<TooltipIconSvg />}
                    tooltipTextIn={tooltipTextIn}
                    tooltipTextInChange={tooltipTextInChange}
                    copiedText={item.address}
                    itemProps={item.address}
                    hideAfter="3000"
                  />
                </span>
              </span>
              <span
                className={`filter_table_wallet-icon disabled !bg-white `}
                onClick={() =>
                  !item?.canBeArchived &&
                  !walletLinkedToSafleId &&
                  !item?.isDeleted &&
                  !(item?.address === userData?.user?.safleIdPublicAddress) &&
                  startRemoveWalletFlow(item)
                }
                //style={{ cursor: ((item.isSafleCard ) || (item.isDeleted)) && "not-allowed" }}
              >
                <span className="filter_table_wallet-tooltiptext cursor-none">
                  {item?.canBeArchived ||
                  walletLinkedToSafleId ||
                  item?.address === userData?.user?.safleIdPublicAddress
                    ? "Wallet cannot be Archived"
                    : item?.isDeleted
                    ? "Wallet already archived"
                    : "Wallet Archive"}
                </span>
                <img
                  src={"images/wallets/wallet-archive.svg"}
                  width="34px"
                  height="34px"
                  alt=""
                  className={`${
                    (item.canBeArchived ||
                      walletLinkedToSafleId ||
                      item?.isDeleted ||
                      item?.address === userData?.user?.safleIdPublicAddress) &&
                    "opacity-50 cursor-not-allowed"
                  }`}
                />
              </span>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

export default ExportedWalletList;
