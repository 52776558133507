/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
// import TokenArrow from "../../../common/TokenArrow/TokenArrow";
// import { formatXMinDecimals } from "../../../../helpers/generalHelper";
import {
  formatMoney,
  // getTokenBalance,
} from "./../../../../helpers/walletHelpers";
import { useApp } from "../../../../helpers/AppProvider";

const WalletAsset = ({ item, isSafleCard }) => {
  const { appData, actions } = useApp();

  const [assetWorth, setAssetWorth] = useState("");
  // const [walletBalance, setWalletBalance] = useState("");
  // const [walletBalancehUSD, setWalletBalanceUSD] = useState("");
  // const [nativeToken, setNativeToken] = useState("");

  //old load
  // const load_balance = async () => {
  //   const wallet = appData.wallets[item.id];

  //   if (wallet) {
  //     const assets = actions.getBalanceAssets(wallet, false);
  //     let totalWorthInUSD = 0.0;
  //     let s = "";
  //     /*for(let i in assets) {
  //               const rate = await actions.getAssetPriceInUSD(assets[i].symbol);
  //               //
  //               const balance = parseFloat( (assets[i].isNative? assets[i]?.balance : getTokenBalance( assets[i]?.balance, assets[i].decimal )) * rate );

  //               totalWorthInUSD += parseFloat( balance );
  //               if( i === 1 ){
  //                   s += '-'+assets[i]?.balance+'-';
  //               }
  //           }
  //           */

  //     // const balance = await actions.getWalletBalance( item?.address, item?.chain_name );
  //     setWalletBalance(wallet?.balance);
  //     // const balanceUsd = await actions.getWalletBalanceFiat( item.address, item?.chain_name, balance );
  //     setWalletBalanceUSD(wallet.balanceFiat);
  //     const ntoken = actions.getNativeToken(item.chain);
  //     setNativeToken(ntoken);

  //     // const balanceAssetsUsd = await actions.getWalletAssetsWorth( item?.id );
  //     setAssetWorth(wallet?.assetsWorth);
  //   }
  // };
  // useEffect(load_balance, [appData]);

  useEffect(() => {
    if (!appData?.priceData || !appData.portfolioData) {
      return;
    }
    let assets;
    const AssetsInWallet = appData?.portfolioData[item?.address]?.activeChains
      ?.filter((chain) => chain !== "avalanche")
      ?.map((chain) => {
        let AssetsInChainInWallet;
        if (chain !== "bitcoin" && item.type === "evm") {
          AssetsInChainInWallet = [
            ...appData?.portfolioData[item?.address][chain]?.tokens,
          ];
        }
        AssetsInChainInWallet =
          AssetsInChainInWallet?.length > 0
            ? AssetsInChainInWallet.map((obj) => {
                return {
                  ...obj,
                  chain: chain,
                  value: parseFloat(
                    appData?.priceData?.filter(
                      (itemPrice) => itemPrice?.symbol === obj?.symbol
                    )[0]?.price *
                      (parseFloat(obj?.balance) / Math.pow(10, obj?.decimals))
                  ),
                  // ?.toFixed(2),
                  // ?.replace(/\.?0+$/, ""),
                };
              })
            : [];
        AssetsInChainInWallet.push({
          token_address: null,
          symbol: appData?.portfolioData[item?.address][chain]?.symbol,
          name: appData?.portfolioData[item?.address][chain]?.nativeCurrency,
          logo: appData?.portfolioData[item?.address][chain]?.imageURL,
          thumbnail: null,
          decimals: null,
          balance: appData?.portfolioData[item?.address][chain]?.balance,
          possible_spam: false,
          chain: chain,
          value: parseFloat(
            appData?.priceData?.filter(
              (itemPrice) =>
                itemPrice?.symbol ===
                appData?.portfolioData[item?.address][chain]?.symbol
            )[0]?.price *
              parseFloat(appData?.portfolioData[item?.address][chain]?.balance)
          ),
          // ?.toFixed(2),
          // ?.replace(/\.?0+$/, ""),
        });
        return AssetsInChainInWallet;
      });
    assets = AssetsInWallet?.reduce((acc, obj) => {
      return acc?.concat(obj);
    }, []);
    let totalBalance = 0;
    for (let i = 0; i < assets?.length; i++) {
      totalBalance += isNaN(assets[i]?.value)
        ? 0.0
        : parseFloat(assets[i]?.value);
    }

    setAssetWorth(totalBalance);
  }, [appData.accounts, appData.portfolioData, appData.priceData]);

  const currency = actions.getActiveCurrencySymbol();
  //pull the selected currency
  //change the currency simbol and price
  //create helper for the Amount in $
  return (
    <div className="card_wallet-asset p-[10px]">
      <div className="card_wallet-asset-worth-wrp">
        <div className="card_wallet-asset-worth-currency">
          {currency}
          {formatMoney(actions.activeCurrency(assetWorth))}
        </div>
        <div className="card_wallet-asset-worth-label">ASSETS worth</div>
        <div className="card_wallet-asset-worth-total-value">
          {/* {!isSafleCard && (
            <div className="token-coin-ammount-wrp">
              <span className="asset-coin">
                {formatXMinDecimals(walletBalance, 4, 8)}
              </span>
              <span className="asset-prefix">{nativeToken}</span>
            </div>
          )} */}
          {/* {!isSafleCard &&
                        <div className="token-coin-ammount-value-currency">
                            (
                            <span className='currency-symbol'>{currency}</span>
                            <span className='asset-currency-val'>{formatMoney( actions.activeCurrency( walletBalancehUSD ) )}</span>
                            )
                        </div>
                    } */}
        </div>
      </div>
    </div>
  );
};

export default WalletAsset;
