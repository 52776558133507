import {
  generatePDKey,
  createPDKeyHash,
  getCloudToken,
  generateEncryptionKey,
  retrieveEncryptionKey,
  retrieveVaultFromCloud,
  updateVaultOnCloud,
  encryptEncryptionKey,
  decryptEncryptionKey,
  hashPassword,
  saveVaultLogs,
} from "../helpers/vaultHelpers";
import Storage from "./../helpers/Storage";
import APIS from "./../helpers/apis";
import { delay_code, dataURLtoBlob } from "../helpers/generalHelper";
import initialStateUser from "../context/initialStateUser";

import blockies from "ethereum-blockies";

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const ethers = require("ethers");
const axios = require("axios");
const Web3 = require("web3");

const Vault = require("@getsafle/safle-vault");
var sb = require("satoshi-bitcoin");
class UserController {
  constructor(userData, setData, props = {}) {
    this.props = props;
    this.userData = userData;
    this.setUserData = (changes, updateGlobal = true) => {
      const tmp = { ...this.userData };
      this.userData = { ...tmp, ...changes };
      if (updateGlobal) {
        setData((state) => {
          return { ...state, ...this.userData };
        });
      }
    };
    this.vault = new Vault({
      vault: Storage.load("user").vault,
      encryptionKey: Storage.load("user")?.decriptionKey
        ? Object.values(Storage.load("user")?.decriptionKey)
        : null,
    });
  }
  async updateVaultToken(token) {
    const userData = this.userData;
    userData.user = { ...userData.user, token: token };
    this.setUserData(userData);
    const stor = Storage.load("user");
    stor.token = token;
    Storage.save("user", stor);
    delay_code(800);
  }

  async updateSafleID(safleID) {
    const userData = this.userData;
    userData.user = { ...userData.user, safleID: safleID };
    this.setUserData(userData);
    const stor = Storage.load("user");
    stor.safleID = safleID;
    Storage.save("user", stor);
    delay_code(800);
  }

  async updateVaultData(vaultEnc) {
    const stor = Storage.load("user");

    let response = await updateVaultOnCloud(
      stor.pdkeyHash,
      stor.token,
      vaultEnc
    );
    if (response.statusCode !== 201) return false;

    const userData = this.userData;
    userData.user = { ...userData.user, vault: vaultEnc };
    this.setUserData(userData);
    stor.vault = vaultEnc;
    Storage.save("user", stor);
    delay_code(800);
    return true;
  }

  async login(safleID, password, token, passPlainPassword = false) {
    //passPassword = true is to pass plain password in params instead of pdkeyHash
    let passwordDerivedKey = await generatePDKey({ safleID, password });
    const pdkeyHash = await createPDKeyHash({ passwordDerivedKey });
    let params = {
      userName: safleID,
      password: !passPlainPassword ? pdkeyHash : password,
      "g-recaptcha-response": token,
    };
    let respStatusCode = "";
    const resp = await fetch(APIS.login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((r) => {
        respStatusCode = r.status;
        return r.json();
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return Promise.reject(err);
      });

    if (resp.statusCode === 201) {
      Storage.save("user", {
        safleID: safleID,
        token: resp.data.token,
        pdkeyHash,
      });
      this.setUserData(
        {
          user: {
            ...this.userData.user,
            safleID: safleID,
            token: resp.data.token,
          },
        },
        true
      );
    } else if (respStatusCode === 400 || resp.statusCode === 400) {
      if (!resp.statusCode) {
        resp["statusCode"] = 400;
      }
      return resp;
    } else {
      return Promise.reject(resp);
    }

    await delay_code(1000);

    return resp;
  }

  async logout(page) {
    Storage.remove("registration");
    this.setUserData(initialStateUser);
    Storage.remove("user");
    Storage.remove("settings");
    Storage.remove("transactionResult");
    delay_code(400);
    if (page) {
      this.props.navigate && this.props.navigate(page, { replace: true });
    } else {
      this.props.navigate && this.props.navigate("/", { replace: true });
    }
  }

  isLoggedIn() {
    const stor = Storage.load("user");

    return !!(stor?.token && stor?.vault);
  }

  getSafleUser = async (email) => {
    return await fetch(APIS.get_safle_id_email + email, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          return resp.data;
        }
        return resp;
      })
      .catch((err) => {
        return err;
      });
  };

  async register(safleID, name, email, password, token, encryptionKey) {
    let passwordDerivedKey = await generatePDKey({ safleID, password });
    let hashedPassword = await hashPassword({ password, passwordDerivedKey });
    let encryptedEncryptionKey = await encryptEncryptionKey({
      passwordDerivedKey,
      encryptionKey,
    });
    let PDKeyHash = await createPDKeyHash({ passwordDerivedKey });
    let params = {
      name: name,
      email: email,
      hashedPassword: hashedPassword,
      encryptedEncryptionKey: Object.values(encryptedEncryptionKey),
      safleId: safleID,
      PDKeyHash: PDKeyHash,
      "g-recaptcha-response": token,
    };

    return fetch(APIS.registration, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((r) => r.json())
      .then((resp) => resp)
      .catch((err) => {});
  }
  async retrieveEncKey(cloudTok, safleID, password, token) {
    let passwordDerivedKey = await generatePDKey({ safleID, password });
    const pdkeyHash = await createPDKeyHash({ passwordDerivedKey });
    let encryptionKey = await retrieveEncryptionKey(pdkeyHash, cloudTok);
    if (typeof encryptionKey === "object") {
      encryptionKey = Object.values(encryptionKey);
    }
    const decryptedKey = decryptEncryptionKey(
      safleID,
      password,
      encryptionKey,
      "object"
    );
    Storage.save("encrypted_key", {
      encryption_key: decryptedKey,
    });
    const old_storage = Storage.load("registration");
    Storage.save("registration", {
      ...old_storage,
      encryptionKey: decryptedKey,
    });
    delay_code(400);
  }

  async retrieveVaultAddress(safleID, password, newPasswordToken, pin) {
    let cloudTok = newPasswordToken;

    let passwordDerivedKey = await generatePDKey({ safleID, password });
    const pdkeyHash = await createPDKeyHash({ passwordDerivedKey });
    let encVault = await retrieveVaultFromCloud(pdkeyHash, cloudTok);
    let encryptionKey = await retrieveEncryptionKey(pdkeyHash, cloudTok);
    if (typeof encryptionKey === "object") {
      encryptionKey = Object.values(encryptionKey);
    }
    const decryptedKey = decryptEncryptionKey(
      safleID,
      password,
      encryptionKey,
      "object"
    );
    const old_storage = Storage.load("registration");
    Storage.save("registration", {
      ...old_storage,
      userVault: encVault,
      encryptionKey: decryptedKey,
    });

    this.setUserData(
      {
        user: {
          ...this.userData.user,
          safleID: safleID,
          vault: encVault,
          decriptionKey: decryptedKey,
        },
      },
      true
    );
    return encVault;
  }

  async retrieveVault(safleID, password, token) {
    let cloudToken = await getCloudToken(safleID, password, token);
    let passwordDerivedKey = await generatePDKey({ safleID, password });
    const pdkeyHash = await createPDKeyHash({ passwordDerivedKey });
    let encVault = await retrieveVaultFromCloud(pdkeyHash, cloudToken);
    let encryptionKey = await retrieveEncryptionKey(pdkeyHash, cloudToken);
    if (typeof encryptionKey === "object") {
      encryptionKey = Object.values(encryptionKey);
    }
    const decriptedKey = decryptEncryptionKey(
      safleID,
      password,
      encryptionKey,
      "object"
    );
    const user = Storage.load("user");
    Storage.save("user", {
      ...user,
      vault: encVault,
      decriptionKey: decriptedKey,
    });
    delay_code(400);
    this.setUserData(
      {
        user: {
          ...this.userData.user,
          vault: encVault,
          decriptionKey: decriptedKey,
        },
      },
      true
    );
  }

  async verifyPassword(safleID, password, token) {
    let passwordDerivedKey = await generatePDKey({ safleID, password });
    const pdkeyHash = await createPDKeyHash({ passwordDerivedKey });

    let params = {
      userName: safleID,
      password: pdkeyHash,
      "g-recaptcha-response": token,
    };

    const resp = await fetch(APIS.login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((r) => r.json())
      .then((resp) => resp)
      .catch((err) => {
        return false;
      });
    return resp && resp.hasOwnProperty("statusCode") && resp.statusCode === 201;
  }

  async verifyEmailOTP({ safleID, email, otp, action }) {
    let params = {
      email: email,
      safleId: safleID,
      otp: otp,
      action: action, // "email-verification", "email-confirmation", "change-pin", "forgot-pin"
    };

    return await fetch(APIS.otp_verification, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then(async (resp) => {
        const response = {};
        response["statusCode"] = resp.status;
        if (response.statusCode === 400) {
          const respJson = await resp.json();
          response["details"] = respJson.details;
        } else {
          response["statusCode"] = 201;
          const respJson = await resp.json();
          response["token"] = respJson.data.token;
        }
        return response;
      })
      .catch((err) => {
        return false;
      });
  }

  async verifyChangePinOTP({ otp, email, action, safleId }) {
    let params = {
      otp: otp,
      email: email,
      action: action, // "email-verification", "email-confirmation", "change-email"
      safleId: safleId,
    };

    return await fetch(APIS.otp_verification, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then(async (resp) => {
        const response = {};
        response["statusCode"] = resp.status;
        if (response.statusCode === 400) {
          const respJson = await resp.json();
          response["details"] = respJson.details;
        } else {
          response["statusCode"] = 201;
          const respJson = await resp.json();
          return respJson;
        }
        return response;
      })
      .catch((err) => {
        return false;
      });
  }
  async verifyChangePassOTP({
    otp,
    email,
    action,
    safleId,
    newPDKeyHash,
    oldPDKeyHash,
    hashedPassword,
    vault,
    encryptedEncryptionKey,
  }) {
    let params = {
      otp,
      email: email,
      action: action,
      safleId: safleId,
      newPDKeyHash: newPDKeyHash,
      oldPDKeyHash: oldPDKeyHash,
      hashedPassword: hashedPassword,
      vault: vault,
      encryptedEncryptionKey: encryptedEncryptionKey,
    };

    return await fetch(APIS.otp_verification, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then(async (resp) => {
        const response = {};
        response["statusCode"] = resp.status;
        if (response.statusCode === 400) {
          const respJson = await resp.json();
          response["details"] = respJson.details;
        } else {
          response["statusCode"] = 201;
          const respJson = await resp.json();
          return respJson;
        }
        return response;
      })
      .catch((err) => {
        return false;
      });
  }

  async changePinRequest(currentPin, newPin) {
    let enck = this.getDecriptionKey();

    if (typeof enck !== "object") {
      enck = enck.reduce((acc, item, idx) => {
        acc[idx] = item;
        return acc;
      }, {});
    }

    await this.loadVault(true);

    const resp = await this.vault.changePin(
      currentPin?.toString()?.padStart(6, "0"),
      newPin?.toString()?.padStart(6, "0"),
      enck
    );

    if (resp.hasOwnProperty("response")) {
      await this.updateVaultData(resp.response);
      await this.loadVault();
      const logsRes = await this.vault.getLogs();
      const getAddress = await this.getAccountReg(
        this.userData.user.vault,
        parseInt(newPin),
        enck
      );

      const updateVaultLogsRes = saveVaultLogs(
        logsRes?.logs,
        this.userData.user.token,
        getAddress
      );
      if (updateVaultLogsRes?.statusCode === 200) {
        return true;
      }
      return true;
    } else {
      return false;
    }
  }

  async verifyNewEmailOTP({ safleID, email, otp, action, newEmail, name }) {
    let params = {
      otp: otp,
      email: email,
      action: action, // "email-verification", "email-confirmation", "change-email" , "change-email-verification"
      safleId: safleID,
    };

    return await fetch(APIS.otp_verification, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then(async (resp) => {
        const response = {};
        response["statusCode"] = resp.status;
        if (response.statusCode === 400) {
          const respJson = await resp.json();
          response["details"] = respJson.details;
        } else {
          response["statusCode"] = 201;
          let respJson = await resp.json();
          //sent otp to second email
          let params = {
            email: newEmail,
            name: name,
          };
          await fetch(APIS.resend_otp, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
          }).then(async (resp) => {
            const respJsonOtp = await resp.json();

            respJson = respJsonOtp;
          });
          return respJson;
        }
        return response;
      })
      .catch((err) => {
        return false;
      });
  }

  async verifyChangeEmailOld({ otp, email, action, safleId, name, newEmail }) {
    let params = {
      otp: otp,
      email: email,
      action: action, // "email-verification", "email-confirmation", "change-email" , "change-email-verification"
      safleId: safleId,
    };

    return await fetch(APIS.otp_verification, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then(async (resp) => {
        const response = {};
        response["statusCode"] = resp.status;
        if (response.statusCode === 400) {
          const respJson = await resp.json();
          response["details"] = respJson.details;
        } else {
          response["statusCode"] = 201;
          let respJson = await resp.json();
          //sent otp to second email
          let params = {
            email: newEmail,
            name: name,
          };
          await fetch(APIS.resend_otp, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
          }).then(async (resp) => {
            const respJsonOtp = await resp.json();

            respJson = respJsonOtp;
          });
          return respJson;
        }
        return response;
      })
      .catch((err) => {
        return false;
      });
  }

  async verifyChangeEmailNew({ otp, oldEmail, action, safleId, email }) {
    let params = {
      otp: otp,
      oldEmail: oldEmail,
      action: action, // "email-verification", "email-confirmation", "change-email" , "change-email-verification"
      safleId: safleId,
      email: email,
    };

    return await fetch(APIS.otp_verification, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then(async (resp) => {
        const response = {};
        response["statusCode"] = resp.status;
        if (response.statusCode === 400) {
          const respJson = await resp.json();
          response["details"] = respJson.details;
        } else {
          response["statusCode"] = 201;
          const respJson = await resp.json();
          return respJson;
        }
        return response;
      })
      .catch((err) => {
        return false;
      });
  }

  resendEmailOTP = async (email, safleId) => {
    let params = {
      name: safleId ? safleId : "User",
      email: email,
    };

    return await fetch(APIS.resend_otp, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((r) => r.json())
      .then((resp) => resp)
      .catch((err) => {
        return false;
      });
  };
  async getMnemonic() {
    this.vault = new Vault({});
    const response = await this.vault.generateMnemonic(); // entropy is an optional parameter
    return response;
  }

  async exportMnemonic(pin, address) {
    if (!this.vault) {
      await this.loadVault();
    }
    try {
      const mnemonic = await this.vault.exportMnemonic(
        pin?.toString()?.padStart(6, "0")
      );
      if (mnemonic.response) {
        const logsRes = await this.vault.getLogs();
        await saveVaultLogs(
          logsRes.logs,
          Storage.load("user")?.token,
          address || "address"
        );
      }
      return mnemonic.response;
    } catch (e) {
      return { error: e.message };
    }
  }
  async generateVault(encryptionKey, pin, mnems, token) {
    this.vault = new Vault({});
    const vaultGenerateRes = await this.vault.generateVault(
      Object.values(encryptionKey),
      pin?.toString()?.padStart(6, "0"),
      mnems
    );
    const resLogs = this.vault.getLogs();

    const genAddress = await this.getAccountReg(
      vaultGenerateRes?.response,
      parseInt(pin),
      encryptionKey
    );

    await saveVaultLogs(resLogs?.logs, token, genAddress);
    return vaultGenerateRes;
  }
  async validatePin(pin) {
    await this.loadVault(true);
    delay_code(100);

    try {
      const isPinValid = await this.vault.validatePin(
        pin?.toString()?.padStart(6, "0")
      );
      if (!isPinValid?.error) {
        return isPinValid.response;
      } else {
        return { error: isPinValid?.error };
      }
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }
  async getAccountReg(userVault, pin, encryptionKey) {
    this.vault = new Vault({
      vault: userVault
        ? userVault
        : Storage.load("user").vault
        ? Storage.load("user").vault
        : Storage.load("registration").userVault,
      encryptionKey: Storage.load("user").decriptionKey
        ? Object.values(Storage.load("user").decriptionKey)
        : Storage.load("registration").encryptionKey
        ? Object.values(Storage.load("registration").encryptionKey)
        : null,
    });
    await this.vault.restoreKeyringState(
      userVault
        ? userVault
        : Storage.load("user").vault
        ? Storage.load("user").vault
        : Storage.load("registration").userVault,
      pin?.toString()?.padStart(6, "0"),
      Storage.load("user").decriptionKey
        ? Object.values(Storage.load("user").decriptionKey)
        : Storage.load("registration").encryptionKey
        ? Object.values(Storage.load("registration").encryptionKey)
        : null
    );
    try {
      const { response, error } = await this.vault.getAccounts(
        Storage.load("user").decriptionKey
          ? Object.values(Storage.load("user").decriptionKey)
          : Storage.load("registration").encryptionKey
          ? Object.values(Storage.load("registration").encryptionKey)
          : null
      );
      if (error) {
        console.log(error);
        return false;
      }
      return response[0].address;
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }
  async getAccount(userVault, pin, encryptionKey) {
    let node = process.env.REACT_APP_SIGNUP_NODE
      ? process.env.REACT_APP_SIGNUP_NODE
      : userVault;

    // use local constant instead of property of main object to prevent React Crashing
    const vault = new Vault(node);

    await vault.restoreKeyringState(
      userVault,
      pin?.toString()?.padStart(6, "0"),
      encryptionKey
    );

    const { response, error } = await vault.getAccounts(encryptionKey);
    if (error) {
      return false;
    }
    return response[0].address;
  }

  async exportPrivateKey(address, pin, cloudToken, accountType) {
    this.loadVault(true);
    const userVault = Storage.load("user")?.vault
      ? Storage.load("user").vault
      : Storage.load("registration").userVault;

    const encryptionKey = Storage.load("user")?.decriptionKey
      ? Object.values(Storage.load("user")?.decriptionKey)
      : Storage.load("registration")?.encryptionKey
      ? Object.values(Storage.load("registration")?.encryptionKey)
      : null;

    await this.vault.restoreKeyringState(
      userVault,
      pin?.toString()?.padStart(6, "0"),
      encryptionKey
    );
    if (accountType === "bitcoin") {
      await this.vault.changeNetwork("bitcoin");
    }

    const chain = accountType === "bitcoin" ? "bitcoin" : "ethereum";

    try {
      const privateKeyRes = await this.vault.exportPrivateKey(
        address,
        pin?.toString()?.padStart(6, "0")
      );
      if (privateKeyRes?.response?.privateKey) {
        const token = Storage.load("user").token
          ? this.userData.user.token
          : cloudToken;
        const logsRes = await this.vault.getLogs();
        await saveVaultLogs(logsRes.logs, token, address, chain);
      }
      return privateKeyRes;
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  async changeWalletLabel(address, pin, newLabel, accountType) {
    await this.loadVault(true);
    const enck = this.getDecriptionKey();
    try {
      if (accountType === "bitcoin") {
        await this.vault.changeNetwork("bitcoin");
      }
      const updatedVault = await this.vault.updateLabel(
        address,
        Object.values(enck),
        newLabel
      );

      const chain = accountType === "bitcoin" ? "bitcoin" : "ethereum";

      if (updatedVault.hasOwnProperty("response")) {
        await this.updateVaultData(updatedVault.response);
        //calling post API logs
        const updateLabelGetLogs = this.vault.getLogs();
        const logsRes = await saveVaultLogs(
          updateLabelGetLogs?.logs,
          this.userData.user.token,
          address,
          chain
        );
        if (logsRes.statusCode !== 200) {
          return false;
        }
        delay_code(200);
        return true;
      } else {
        return updatedVault.error;
      }
    } catch (e) {
      return { error: e.message };
    }
  }

  async getTransactionSize(URL, headers) {
    let inputCount = 0;
    let outputCount = 2;

    const proxyBypasser = process.env.REACT_APP_PROXY_URL + "/get";
    const params = {
      url: URL,
      headers: headers,
    };
    // const authToken = Storage.load("user")?.token;
    const utxos = await fetch(proxyBypasser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(params),
    })
      .then((r) => r.json())
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return false;
      });

    // const utxos = await axios({
    //   url: proxyBypasser,
    //   method: "POST",
    //   body: {
    //     url: URL,
    //     headers: headers,
    //   },
    //   // url: `${URL}`,
    //   // method: "GET",
    //   // headers: headers,
    // });

    let totalAmountAvailable = 0;

    let inputs = [];
    utxos.data.outputs.forEach(async (element) => {
      let utxo = {};
      utxo.satoshis = sb.toSatoshi(parseFloat(element.value));
      utxo.script = element.script;
      utxo.address = element.address;
      utxo.txId = element.hash;
      utxo.outputIndex = element.index;
      totalAmountAvailable += utxo.satoshis;
      inputCount += 1;
      inputs.push(utxo);
    });

    let transactionSize = inputCount * 180 + outputCount * 34 + 10 - inputCount;
    return { transactionSize, totalAmountAvailable, inputs };
  }

  async getFeesBTC(rawTransaction) {
    // const { networkType } = this.store.getState()
    const { from } = rawTransaction;
    // const BLOCKCYPHER_BASE_URL =
    //   process.env.REACT_APP_PROXY_URL +
    //   "?url=" +
    //   process.env.REACT_APP_BLOCKCYPHER_API;
    // const SOCHAIN_API_KEY = "jsGr7ioLvkz1Hfo6uwH_CGYdOnIpWtrJ";
    // const SOCHAIN_BASE_URL = `https://chain.so/api/v3/`; //`https://sochain.com/api/v3/`;
    try {
      const headers = { "API-KEY": process.env.REACT_APP_SOCHAIN_API_KEY };
      const URL = process.env.REACT_APP_SOCHAIN_BASE_URL + "/network_info/BTC";

      const proxyBypasser = process.env.REACT_APP_PROXY_URL + "/get";
      const params = {
        url: URL,
        headers: headers,
      };

      const response = await fetch(proxyBypasser, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(params),
      })
        .then((r) => r.json())
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          return false;
        });

      //  + `${networkType === TESTNET.NETWORK ? 'BTCTEST' : "BTC"
      // }`

      let blocks = response?.data["mempool"]?.blocks?.slice(0, 3);

      let fees = {
        slow: {
          satPerByte: parseInt(blocks[2].median_fee_rate),
        },
        standard: {
          satPerByte: parseInt(blocks[1].median_fee_rate),
        },
        fast: {
          satPerByte: parseInt(blocks[0].median_fee_rate),
        },
      };

      // get transaction size
      const sochainURL =
        process.env.REACT_APP_SOCHAIN_BASE_URL +
        `/unspent_outputs/${
          "BTC"
          // networkType === TESTNET.NETWORK ? "BTCTEST" : "BTC"
        }/${from}`;
      // }/bc1qxhmdufsvnuaaaer4ynz88fspdsxq2h9e9cetdj/1`;
      // const headers = { "API-KEY": process.env.REACT_APP_SOCHAIN_API_KEY };

      let { transactionSize } = await this.getTransactionSize(
        sochainURL,
        headers
      );

      return {
        transactionSize,
        fees,
      };
    } catch (err) {
      throw err;
    }
  }

  async getBTCFees(txn) {
    // console.log(txn, "txn");
    await this.loadVault(true);
    this.vault.changeNetwork("bitcoin");
    let response = await this.getFeesBTC(txn);
    // let response = await this.vault.getFees(txn);

    // response = {
    //   ...response,
    //   // transactionSize: 21360,
    //   fees: {
    //     slow: {
    //       satPerByte: 10,
    //     },
    //     standard: {
    //       satPerByte: 15,
    //     },
    //     fast: {
    //       satPerByte: 18,
    //     },
    //   },
    // };
    return response;
  }

  async importWallet(privateKey, pinValue, chain, walletLabel = false) {
    await this.loadVault(true);

    try {
      const decKey = this.getDecriptionKey();
      const resp = await this.vault.validatePin(
        pinValue?.toString()?.padStart(6, "0")
      );
      if (!resp.response) {
        throw new Error("Incorrect pin");
      }
      if (chain) {
        await this.vault.changeNetwork(chain);
      }
      const privKey =
        privateKey.indexOf("0x") !== 0 && chain !== "bitcoin"
          ? "0x" + privateKey
          : privateKey;
      let importedVault = await this.vault.importWallet(
        privKey,
        pinValue?.toString()?.padStart(6, "0"),
        Object.values(decKey)
      );

      let vaultEnc;

      if (importedVault.hasOwnProperty("error")) {
        vaultEnc = null;
      } else if (importedVault.hasOwnProperty("response") && walletLabel) {
        await this.vault.updateLabel(
          importedVault.response.address,
          Object.values(decKey),
          walletLabel
        );

        vaultEnc = importedVault?.response?.vault;
      } else {
        vaultEnc = importedVault?.response?.vault;
      }

      const logsRes = await this.vault.getLogs();
      await saveVaultLogs(
        logsRes?.logs,
        this.userData.user.token,
        importedVault?.response?.address,
        chain
      );
      await this.updateVaultData(vaultEnc);

      return {
        responseVault: vaultEnc,
        newAcc: importedVault?.response?.address,
        error: importedVault?.error,
      };
    } catch (error) {
      if (error.message.indexOf("Seed phrase") !== -1) {
        return { error: "Invalid pin" };
      }
      return { responseVault: null, newAcc: null, error: error };
    }
  }

  async validateSafleEmail(email) {
    return await fetch(APIS.check_email + email, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return false;
      });
  }
  async removeWallet(accountType, address, pin) {
    await this.loadVault(true);
    const decKey = this.getDecriptionKey();
    if (accountType === "bitcoin") {
      await this.vault.changeNetwork("bitcoin");
    }
    const resp = await this.vault.deleteAccount(
      decKey,
      address,
      pin?.toString()?.padStart(6, "0")
    );

    const chain = accountType === "bitcoin" ? "bitcoin" : "ethereum";

    if (resp.hasOwnProperty("response")) {
      await this.updateVaultData(resp.response);
      const getLogs = await this.vault.getLogs();
      await saveVaultLogs(
        getLogs?.logs,
        this.userData.user.token,
        address,
        chain
      );

      delay_code(200);
      return true;
    } else {
      return resp.error;
    }
  }

  async restoreWallet(address, pin, accountType) {
    await this.loadVault(true);
    const decKey = this.getDecriptionKey();
    if (accountType === "bitcoin") {
      await this.vault.changeNetwork("bitcoin");
    }
    const resp = await this.vault.restoreAccount(
      Object.values(decKey),
      address,
      pin?.toString()?.padStart(6, "0")
    );

    const chain = accountType === "bitcoin" ? "bitcoin" : "ethereum";

    if (resp.hasOwnProperty("response")) {
      await this.updateVaultData(resp?.response);
      const getVaultLogs = await this.vault.getLogs();
      await saveVaultLogs(
        getVaultLogs?.logs,
        this.userData.user.token,
        address,
        chain
      );

      delay_code(200);
      return true;
    }
  }

  async changeNameRequest(token, newName) {
    return await fetch(process.env.REACT_APP_AUTH_URL + "/auth/name/", {
      method: "PATCH",
      body: JSON.stringify({
        newName: newName,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((r) => r.json())
      .then((resp) => {
        return resp;
      })
      .catch((err) => {});
  }
  async changeEmailRequest(token) {
    return await fetch(
      process.env.REACT_APP_AUTH_URL + "/auth/change-email-request/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((r) => r.json())
      .then((resp) => {
        return resp;
      })
      .catch((err) => {});
  }

  async changePasswordRequest(token) {
    return await fetch(
      process.env.REACT_APP_AUTH_URL + "/auth/change-password-request/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((r) => r.json())
      .then((resp) => {
        return resp;
      })
      .catch((err) => {});
  }

  async changeSafleId(userData, newSafleId, pinValue, password, token) {
    try {
      const {
        vault: userVault,
        safleID: previousSafleId,
        token: authToken,
        decriptionKey: encKey,
      } = Storage.load("user");
      if (!userVault) throw Error("Vault not ready");

      const publicAddress = await this.getAccountReg(
        userVault,
        parseInt(pinValue),
        encKey
      );

      const web3 = new Web3(
        new Web3.providers.HttpProvider(process.env.REACT_APP_ETH_NODE)
      );
      const privateKey = (
        await this.exportPrivateKey(publicAddress, parseInt(pinValue))
      ).response?.privateKey;

      const accountObject = await web3?.eth?.accounts.privateKeyToAccount(
        privateKey
      );
      const signedData = await accountObject.sign(publicAddress, privateKey);

      const params = {
        userAdd: publicAddress,
        previousSafleId, // current safleId(can be fetched from the login token)
        newSafleId, //  safleId user wants to set
        signedData,
      };

      return await fetch(APIS.change_safleid, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(params),
      })
        .then((e) => e.json())
        .then(async (result) => {
          if (!result.hasOwnProperty("error")) {
            const passwordDerivedKey = await generatePDKey({
              safleID: previousSafleId,
              password: password,
            });
            const pdkeyHash = await createPDKeyHash({ passwordDerivedKey });
            let encriptedKey = await retrieveEncryptionKey(
              pdkeyHash,
              authToken
            );
            if (typeof encriptedKey === "object") {
              encriptedKey = Object.values(encriptedKey);
            }
            const decKey = decryptEncryptionKey(
              previousSafleId,
              password,
              encriptedKey
            );

            const passwordDerivedKeyNew = await generatePDKey({
              safleID: newSafleId,
              password: password,
            });
            const pdkeyHashNew = await createPDKeyHash({
              passwordDerivedKey: passwordDerivedKeyNew,
            });
            const encKeyNew = await encryptEncryptionKey({
              passwordDerivedKey: passwordDerivedKeyNew,
              encryptionKey: decKey,
            });
            //

            await fetch(APIS.update_credentials, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${result.data.token}`,
              },
              body: JSON.stringify({
                PDKeyHash: pdkeyHashNew,
                encryptedEncryptionKey: encKeyNew,
              }),
            }).then((e) => e.json());

            this.setUserData({
              ...this.userData,
              user: {
                ...Storage.load("user"),
                safleID: newSafleId,
                pdkeyHash: pdkeyHashNew,
              },
            });
            Storage.save("user", {
              ...Storage.load("user"),
              safleID: newSafleId,
              pdkeyHash: pdkeyHashNew,
            });
            this.retrieveVault(newSafleId, password, token);

            return { result };
          }

          return { error: result.error };
        })
        .catch((err) => {
          return { error: err };
        });
    } catch (error) {
      return { error };
    }
  }

  async validateSafleID(safleId) {
    return await fetch(
      process.env.REACT_APP_CHECKING_SERVICE_URL +
        "/checking/check-safleid/" +
        safleId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((r) => r.json())
      .then((resp) => {
        return resp;
      })
      .catch((err) => {});
  }

  async getSafleIdByEmail(email) {
    return await fetch(APIS.get_safle_id_email + email, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          return resp.data.safleId.toString();
        }
        return null;
      })
      .catch((err) => {
        return false;
      });
  }

  async getEmailBySafleId(safleId) {
    return await fetch(APIS.get_safle_id_email + safleId, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          return resp.data.email.toString();
        }
        return null;
      })
      .catch((err) => {
        return false;
      });
  }

  forgotPassword(safleId, token) {
    let params = {
      userName: safleId,
      "g-recaptcha-response": token,
    };

    return fetch(APIS.forgot_pass, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((r) => r.json())
      .then((resp) => resp)
      .catch((err) => {
        return false;
      });
  }

  async changePassword(
    email,
    safleID,
    password,
    confirmSeeds,
    pin,
    recaptchaToken
  ) {
    // const enck = this.getDecriptionKey();
    // const encryptionKey = Object.values(enck);
    const encryptionKey = Array.from(await generateEncryptionKey());
    const passwordDerivedKey = await generatePDKey({ safleID, password });
    const encKey = await encryptEncryptionKey({
      passwordDerivedKey,
      encryptionKey,
    });
    const pdKeyHash = await createPDKeyHash({ passwordDerivedKey });
    const hashedPassword = await hashPassword({ password, passwordDerivedKey });
    const vault = new Vault({});
    const vRec = await vault.generateVault(
      encryptionKey,
      pin?.toString()?.padStart(6, "0"),
      confirmSeeds
    );

    const publicAddress = await this.getAccountFromMnemmonic(confirmSeeds);

    // const publicAddress = addresses.response && addresses.response[0]?.address;

    const params = {
      email: email,
      hashedPassword: hashedPassword,
      encryptedEncryptionKey: Object.values(encKey),
      PDKeyHash: pdKeyHash,
      vault: vRec.response,
      "g-recaptcha-response": recaptchaToken,
      publicAddress: publicAddress,
    };

    try {
      const res = await fetch(
        process.env.REACT_APP_AUTH_URL + "/auth/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      )
        .then((r) => r.json())
        .then((resp) => resp)
        .catch((err) => {});

      if (res.statusCode === 201) {
        const unmarshallApiKey = process.env.REACT_APP_UNMARSHALL_KEY;
        const getVaultLogs = await this.getVaultLogs(
          res?.data?.token,
          vRec?.response
        );
        let isNewUser = true;
        getVaultLogs?.logs?.forEach((log) => {
          if (!log?.action) {
            isNewUser = false;
          }
        });
        let vRecover;

        if (isNewUser) {
          vRecover = await vault.recoverVault(
            confirmSeeds,
            encryptionKey,
            pin?.toString()?.padStart(6, "0"),
            unmarshallApiKey,
            "logs", //logs or transactions
            getVaultLogs
          );
        } else {
          vRecover = await vault.recoverVault(
            confirmSeeds,
            encryptionKey,
            pin?.toString()?.padStart(6, "0"),
            unmarshallApiKey,
            "transactions" //logs or transactions
          );
        }

        await updateVaultOnCloud(
          pdKeyHash,
          res?.data?.token,
          vRecover?.response
        );
        return { message: "Password changed succesfully" };
      }
    } catch (e) {}
  }

  async getVaultLogsHistory(token) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_LOGS_SERVICE_URL}?page=1&limit=100`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      ).then((response) => response.json());
      return res.statusCode === 200 && res;
    } catch (e) {
      return e;
    }
  }

  async getVaultLogs(token, vault) {
    const user = Storage.load("user");
    const vaultLogsRes = await this.getVaultLogsHistory(user?.token || token);
    if (vaultLogsRes?.statusCode === 400) {
      vaultLogsRes?.error[0]?.name === "userId"
        ? alert(vaultLogsRes?.error[0]?.message)
        : alert(vaultLogsRes?.error[0]?.message);
    } else if (vaultLogsRes?.statusCode === 200) {
      const logData = vaultLogsRes?.doc?.map((i, index) => {
        return {
          timestamp: i?.timestamp,
          //moment.unix(i?.timestamp).format("DD-MM-YYYY, hh:mm"),
          // activity: i?.activity,
          action:
            i?.action === "add-wallet" || i?.activity === "add-wallet"
              ? "add-account"
              : i?.action || i?.activity,
          address: i?.address,
          platform: i?.platform,
          Where: i?.storage.join(","),
          vault: user?.vault || vault,
          chain: i?.chain,
        };
      });
      logData.sort((a, b) => b.timestamp - a.timestamp);
      console.log(logData);
      return logData;
    }
  }

  async changePin(mnemonic, newPin) {
    const enck = this.getDecriptionKey();
    const encryptionKey = Object.values(enck); // Array.from(await generateEncryptionKey());
    const unmarshallApiKey = process.env.REACT_APP_UNMARSHALL_KEY;
    const vault = new Vault({});

    const getVaultLogs = await this.getVaultLogs();
    let isNewUser = true;
    getVaultLogs?.forEach((log) => {
      if (!log?.action) {
        isNewUser = false;
      }
    });
    let newVault;

    if (isNewUser) {
      newVault = await vault.recoverVault(
        mnemonic,
        encryptionKey,
        newPin?.toString()?.padStart(6, "0"),
        unmarshallApiKey,
        "logs", //logs or transactions
        getVaultLogs
      );
    } else {
      newVault = await vault.recoverVault(
        mnemonic,
        encryptionKey,
        newPin?.toString()?.padStart(6, "0"),
        unmarshallApiKey,
        "transactions" //logs or transactions
      );
    }

    if (newVault.hasOwnProperty("response")) {
      const stor = Storage.load("user");
      let response = await updateVaultOnCloud(
        stor.pdkeyHash,
        stor.token,
        newVault.response
      );
      if (response.statusCode !== 201) return false;

      const vData = {
        user: {
          ...this.userData.user,
          vault: newVault.response,
          decriptionKey: encryptionKey,
        },
      };
      this.setUserData(vData);

      // stor.user = vData.user;
      // Storage.save("user", stor);
      Storage.save("user", {
        ...Storage.load("user"),
        vault: newVault.response,
      });
    }
    return newVault;
  }

  async validateMnemonic(safleId, mnems, token) {
    const vault = new Vault({});
    vault.changeNetwork("polygon");
    try {
      const address = await this.getAccountFromMnemmonic(mnems);

      const resp = await fetch(AUTH_URL + "/auth/validate-public-address/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "g-recaptcha-response": token,
          userName: safleId,
          publicAddress: address,
        }),
      });

      return resp.status === 201;
    } catch (e) {
      return false;
    }
  }
  async validateMnemonicOld(safleId, mnems) {
    const vault = new Vault();
    vault.changeNetwork("polygon");
    try {
      const resp = await vault
        .validateMnemonic(mnems, safleId)
        .catch((e) => console.log(e));
      return resp ? true : false;
    } catch (e) {
      return false;
    }
  }

  async migrateUser(email, password, pin, mnemonic, token) {
    this.vault = new Vault(process.env.REACT_APP_SIGNUP_NODE);
    let safleID = "";
    if (email.indexOf("@") !== -1) {
      safleID = await this.getSafleIdByEmail(email);
    } else {
      safleID = email;
    }
    const encryptionKey = Array.from(await generateEncryptionKey());
    let passwordDerivedKey = await generatePDKey({ safleID, password });
    let PDKeyHash = await createPDKeyHash({ passwordDerivedKey });

    let encryptedEncryptionKey = await encryptEncryptionKey({
      passwordDerivedKey,
      encryptionKey,
    });

    const userVault = await this.vault.generateVault(
      encryptionKey,
      pin?.toString()?.padStart(6, "0"),
      mnemonic
    );

    const address = await this.getAccountFromMnemmonic(mnemonic);
    let params = {
      userName: email,
      password: password,
      PDKeyHash: PDKeyHash,
      encryptedEncryptionKey: Object.values(encryptedEncryptionKey),
      vault: userVault.response,
      userAddress: address,
    };

    return await fetch(APIS.migrate, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((r) => r.json())
      .then((resp) => {
        if (resp.statusCode === 201) {
          return {
            ...resp,
            vault: userVault.response,
            enc: encryptionKey,
            address,
            safleID: safleID,
          };
        } else return resp;
      })
      .catch((err) => {
        return false;
      });
  }

  async signUpProcessFromMigration(userVault, pin, enc, cloudToken) {
    const addr = await this.getAccountReg(userVault, parseInt(pin), enc);
    const privateKey = (
      await this.vault.exportPrivateKey(addr, pin?.toString()?.padStart(6, "0"))
    ).response;
    const txn = await this.relayTransaction({
      publicAddress: addr,
      privateKey: privateKey,
      authToken: cloudToken,
    });
    return txn;
  }

  async getAccountFromMnemmonic(mnemonic) {
    const { address } = ethers.Wallet.fromMnemonic(mnemonic);
    return address;
  }

  async loadVault(hardRefresh = false) {
    if (this.vault && !hardRefresh) {
      return this.vault;
    }

    this.vault = new Vault({
      vault: Storage.load("user")?.vault
        ? Storage.load("user").vault
        : Storage.load("registration").userVault,
      encryptionKey: Storage.load("user")?.decriptionKey
        ? Object.values(Storage.load("user")?.decriptionKey)
        : Storage.load("registration")?.encryptionKey
        ? Object.values(Storage.load("registration")?.encryptionKey)
        : null,
    });

    return this.vault;
  }

  async getTransactionVault(pin) {
    await this.loadVault();
    const decKey = this.getDecriptionKey();
    await this.vault.restoreKeyringState(
      this.userData.user.vault,
      pin?.toString()?.padStart(6, "0"),
      decKey
    );
    return this.vault;
  }

  async getAddreses() {
    if (this.userData.user.vault) {
      const decKey = this.getDecriptionKey();
      const accounts = (await this.vault.getAccounts(decKey)).response;
      return accounts;
    }
    return [];
  }

  async getSafleAddress() {
    if (!this.vault) {
      await this.loadVault();
    }
    const accs = await this.getAddreses();
    return accs[0];
  }

  async getProfileImg() {
    //check api for existing file
    const endpoint = process.env.REACT_APP_FILE_API;
    const resp = await fetch(endpoint + "/file", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.userData.user.token,
      },
    })
      .then((e) => e.json())
      .catch((e) => e);
    if (
      resp.statusCode === 400 &&
      resp.details[0].message.indexOf("No data found") !== -1
    ) {
      //generate blockie
      const profile_img = blockies.create({
        seed: this.userData.user.safleID,
        color: "#522d70",
        bgcolor: "#e99ce7",
        size: 8,
        scale: 10,
        spotcolor: "#4f1f3c",
      });

      // data url to blob

      const img_str = profile_img.toDataURL();
      const img_blob = dataURLtoBlob(img_str);
      //save blockie
      const data = new FormData();
      data.append("file", img_blob);
      data.append("createdBy", this.userData.user.safleID);
      await fetch(endpoint + "/file", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.userData.user.token,
        },
        body: data,
      });

      return img_str;
    }

    return resp?.data?.imageUrl;
  }

  // profile
  async updateProfileImage(img_str, progressHandler = () => {}) {
    const endpoint = process.env.REACT_APP_FILE_API;

    const img_blob = dataURLtoBlob(img_str);
    //save blockie

    const data = new FormData();
    data.append("file", img_blob);
    data.append("createdBy", this.userData.user.safleID);

    let count = 1;
    const saveResp = await new Promise((res, rej) => {
      const prog = () => {
        count += 1.7;
        progressHandler(count / 100);
        if (count < 100) {
          setTimeout(prog, 20);
        }
      };
      prog();

      setTimeout(async () => {
        const data = new FormData();
        data.append("file", img_blob);
        data.append("createdBy", this.userData.user.safleID);
        const saveResp = await fetch(endpoint + "/file", {
          method: "PATCH",
          headers: {
            Authorization: "Bearer " + this.userData.user.token,
          },
          body: data,
        }).then((e) => e.json());
        //
        this.setUserData({
          user: { ...this.userData.user, avatar: saveResp.data.imageUrl },
        });
        res(saveResp);
        count = 100;
      }, 1000);
    });

    return saveResp;
  }

  async getAccounts(showDeleted = false) {
    await this.loadVault(true);
    if (this.userData.user.vault) {
      let decKey = Object.values(this.userData.user.decriptionKey);
      let accounts = await this.vault.getVaultDetails(decKey);
      if (accounts.error) {
        decKey = this.userData.user.decriptionKey;
        if (!Array.isArray(decKey)) {
          decKey = Object.values(this.userData.user.decriptionKey);
          decKey = new Uint8Array(decKey);
        }
        accounts = await this.vault.getVaultDetails(decKey);

        this.setUserData({ keyType: "object" });
      } else {
        this.setUserData({ keyType: "array" });
      }
      if (accounts.error) {
      }
      const gendAcc = accounts?.response?.evm?.generatedWallets || {};
      const importedAcc = accounts?.response?.evm?.importedWallets || {};
      const allAccts = [
        ...Object.values(gendAcc),
        ...Object.values(importedAcc),
      ].reduce((acc, el, idx) => {
        if (!showDeleted && !el.isDeleted) {
          acc[idx] = el;
        }
        return acc;
      }, {});

      return allAccts;
    }
    return [];
  }

  async detectAssets(addreses) {
    const chains = [
      "ethereum",
      "polygon",
      "bsc",
      "optimism",
      "arbitrium",
      "mantle",
      "velas",
    ];
    try {
      await this.vault.getAssets({
        addresses: addreses,
        chains: chains,
        EthRpcUrl:
          "https://api.etherscan.io?apikey=" +
          process.env.REACT_APP_ETHERSCAN_KEY,
        polygonRpcUrl:
          "https://api.polygonscan.com?apikey=" +
          process.env.REACT_APP_POLYGONSCAN_KEY,
        bscRpcUrl:
          "https://api.bscscan.com?apikey=" + process.env.REACT_APP_BSCSCAN_KEY,
      });

      return false;
    } catch (e) {
      return false;
    }
  }

  async getAccountAssets(address) {
    return [];
  }

  async troll(addr) {}

  getDecriptionKey() {
    let dec;
    // eslint-disable-next-line valid-typeof
    if (typeof this.userData.user.decriptionKey === "array") {
      dec = this.userData.user.decriptionKey.reduce((acc, item, idx) => {
        acc[idx] = item;
        return acc;
      }, {});
    } else {
      if (this.userData.keyType === "object") {
        dec = this.userData.user.decriptionKey;
      } else {
        dec = this.userData.user.decriptionKey
          ? Object.values(this.userData.user.decriptionKey)
          : Storage.load("user")?.decriptionKey;
      }
    }
    return dec;
  }

  async sendVault(userVault, safleID, password, authTok) {
    let passwordDerivedKey = await generatePDKey({
      safleID: safleID,
      password: password,
    });
    const pdKeyHash = await createPDKeyHash({ passwordDerivedKey });
    const vaultParams = {
      vault: userVault,
      PDKeyHash: pdKeyHash,
    };
    //push vault to safle
    const url = `${process.env.REACT_APP_AUTH_URL}/vault`;
    try {
      const res = await this.postRequest({
        params: vaultParams,
        url: url,
        authToken: authTok,
      });
      return res.response;
    } catch (e) {
      return e;
    }
  }

  async relayTransaction({ publicAddress, privateKey, authToken }) {
    const url = `${process.env.REACT_APP_RELAYER_V2_SERVICE_URL}/set-safleid`;

    const web3 = await new Web3(
      new Web3.providers.HttpProvider(process.env.REACT_APP_WEB3_PROVIDER_URL)
    );

    const accountObject = web3.eth.accounts.privateKeyToAccount(privateKey);
    const signedData = accountObject.sign(publicAddress, privateKey);

    const params = {
      userAdd: publicAddress,
      signedData,
    };

    const { error, response } = await this.postRequest({
      params,
      url,
      authToken,
    });

    if (error) {
      return { error };
    }

    return { response };
  }

  async postRequest({ params, url, authToken }) {
    try {
      const response = await axios({
        url,
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: params,
      });

      return { response: response.data };
    } catch (error) {
      return { error: error.response.data };
    }
  }

  async getUserById() {
    try {
      const url = `${process.env.REACT_APP_AUTH_URL}/auth/me`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + Storage.load("user").token,
          "Content-Type": "application/json",
        },
      })
        .then((e) => e.json())
        .then((res) => {
          const userData = this.userData;
          userData.user = {
            ...userData.user,
            linkedWallets: res.data.linkedWallets,
            oldLinkedWallets: res.data.oldLinkedWallets,
            safleIdPublicAddress: res.data.publicAddress,
          };
          this.setUserData(userData);
          return {
            linkedWallets: res.data.linkedWallets,
            oldLinkedWallets: res.data.oldLinkedWallets,
            safleIdPublicAddress: res.data.publicAddress,
          };
        });
      return res;
    } catch (e) {
      return e;
    }
  }
}

export default UserController;
