/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import Web3 from "web3";
// import { formatPrice } from "../../../helpers/generalHelper";
// import { formatMoney } from "../../../helpers/walletHelpers";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";

import TooltipBlackBackground from "./../../common/TooltipBlackBackground/TooltipBlackBackground";
import { formatMoney } from "../../../helpers/walletHelpers";

const GasFeeOptionsBitcoin = ({
  // fees,
  // chosenFee,
  chosenGas,
  chain,
  tokenSymbol = "",
  // estimator = false,
  onGasChange = false,
  onCustomGas = false,
  onAdvancedChange = false,
  loading = false,
  // compType,
  // NFTChainId,
  token,
  toAddress,
  fromAddress,
  value,
  contractAddress,
  modalData,
  type,
}) => {
  const { appData, actions } = useApp();
  const { userActions } = useUser();
  // const [fee, setFee] = useState(0);
  const [fiatFee, setFiatFee] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [errorMsg, setErrorMsg] = useState("");
  const [timeEstimate, setTimeEstimate] = useState("Likely in 30 Seconds");
  const [customGasError, setCustomGasError] = useState("");
  const [customPrioFeeError, setCustomPrioFeeError] = useState("");
  const [customGas, setCustomGas] = useState(chosenGas);
  const [gasFees, setGasFees] = useState();
  const [customPrioFee, setCustomPrioFee] = useState(0);
  const [advanceVisible, setAdvanceVisible] = useState(false);
  const [customGasFee, setCustomGasFee] = useState("standard");
  const currency = actions.getActiveCurrencySymbol();
  useEffect(() => {
    async function getGasFees() {
      let gasFeesRes;

      if (chain === "bitcoin") {
        gasFeesRes = await userActions.getBTCFees({
          from: fromAddress,
          to: toAddress,
          amount: value,
        });
      }

      setGasFees(gasFeesRes);
      setCustomGas(chosenGas ? chosenGas : gasFeesRes?.transactionSize);
      setCustomPrioFee(gasFeesRes?.fees?.standard?.satPerByte || 0);
    }
    getGasFees();
  }, [
    chain,
    token,
    toAddress,
    fromAddress,
    value,
    contractAddress,
    appData.activeChain,
    type,
    modalData,
    chosenGas,
  ]);

  useEffect(() => {
    async function getUSDrate() {
      const usdFeesRes = await actions.getAssetLatestPrice("BTC");
      setFiatFee(
        customGasFee === "custom"
          ? parseFloat(
              usdFeesRes?.data?.[
                tokenSymbol === "ARB" || tokenSymbol === "OP"
                  ? "ETH"
                  : tokenSymbol
              ]?.quote?.USD?.price
            ) *
              ((parseFloat(
                // parseFloat(gasFees?.estimatedBaseFee) +
                parseFloat(customPrioFee)
              ) *
                customGas) /
                Math.pow(10, 8))
          : chosenGas
          ? parseFloat(
              usdFeesRes?.data?.[
                tokenSymbol === "ARB" || tokenSymbol === "OP"
                  ? "ETH"
                  : tokenSymbol
              ]?.quote?.USD?.price
            ) *
            ((parseFloat(
              // parseFloat(gasFees?.estimatedBaseFee) +
              parseFloat(gasFees?.fees?.[customGasFee]?.satPerByte)
            ) *
              chosenGas) /
              Math.pow(10, 8))
          : parseFloat(
              usdFeesRes?.data?.[
                tokenSymbol === "ARB" || tokenSymbol === "OP"
                  ? "ETH"
                  : tokenSymbol
              ]?.quote?.USD?.price
            ) *
            ((parseFloat(
              // parseFloat(gasFees?.estimatedBaseFee) +
              parseFloat(gasFees?.fees?.[customGasFee]?.satPerByte)
            ) *
              gasFees?.transactionSize) /
              Math.pow(10, 8))
      );
      setTimeEstimate(
        customGasFee === "fast"
          ? "Very likely in < 15 seconds"
          : customGasFee === "standard"
          ? "Likely in < 30 seconds"
          : customGasFee === "slow"
          ? "Maybe in 30 seconds"
          : parseFloat(customPrioFee) >= gasFees?.fees?.fast?.satPerByte
          ? "Very likely in < 15 seconds"
          : parseFloat(customPrioFee) < gasFees?.fees?.fast?.satPerByte &&
            parseFloat(customPrioFee) >= gasFees?.fees?.standard?.satPerByte
          ? "Likely in < 30 seconds"
          : "Maybe in 30 seconds"
      );
    }
    getUSDrate();
  }, [gasFees, customGasFee, customGas, customPrioFee, chosenGas]);

  // const [openTooltip, setOpenTooltip] = useState(false);

  const handleChange = (event) => {
    setCustomGasFee(event.target.value);
    onGasChange && onGasChange(event.target.value);
    if (event.target.value === "custom") {
      setCustomGas(chosenGas ? chosenGas : gasFees?.transactionSize);
      setCustomPrioFee(gasFees?.fees?.standard?.satPerByte || 0);
    } else {
      setCustomGas(gasFees?.transactionSize);
      setCustomPrioFee(gasFees?.fees?.[event.target.value]?.satPerByte);
      setCustomGasError("");
      setCustomPrioFeeError("");
    }
  };

  useEffect(() => {
    onAdvancedChange && onAdvancedChange(advanceVisible);
  }, [advanceVisible]);

  useEffect(() => {
    onCustomGas(customGas, customPrioFee);
  }, [customGas, customPrioFee]);

  // const handleTooltip = (event) => {
  //     // setCustomGasFee(event.target.value);
  //     setOpenTooltip(!openTooltip);
  // }

  const validateCustomGas = (value) => {
    if (value < chosenGas) {
      setCustomGasError(`Minimum gas limit should be ${chosenGas}`);
    } else {
      setCustomGasError("");
    }
  };

  const validateCustomPrioFee = (value) => {
    const maxPrioFee = gasFees
      ? parseFloat(gasFees?.fees?.slow?.satPerByte)
      : 0;
    if (value < maxPrioFee) {
      setCustomPrioFeeError(`Minimum priority fee should be ${maxPrioFee}`);
    } else {
      setCustomPrioFeeError("");
    }
  };

  const handleCustomGasChange = (e) => {
    setCustomGas(e.target.value);
    validateCustomGas(e.target.value);
  };

  const handleCustomPrioFeeChange = (e) => {
    setCustomPrioFee(e.target.value);
    validateCustomPrioFee(e.target.value);
  };

  return (
    <div
      className={
        advanceVisible ? "gas__fees__adv-wrp show" : "gas__fees__adv-wrp"
      }
    >
      <div className="gas__fees__adv-top no_margin_bottom">
        <div className="gas__fees_adv-amount">
          <div
            className="gas__fees_adv-amounts"
            style={{ opacity: loading ? 0.35 : 1 }}
          >
            <span className="gas_label">Max Fee:</span>{" "}
            <span className="gas_amount">
              {gasFees
                ? customGasFee === "custom"
                  ? customGas && customPrioFee
                    ? (
                        parseFloat(
                          // parseFloat(gasFees?.estimatedBaseFee) +
                          parseFloat(customPrioFee)
                        ) *
                        (parseFloat(customGas) / Math.pow(10, 8))
                      ).toFixed(8)
                    : 0
                  : chosenGas
                  ? (
                      parseFloat(
                        // parseFloat(gasFees?.estimatedBaseFee) +
                        parseFloat(gasFees?.fees?.[customGasFee]?.satPerByte)
                      ) *
                      (parseFloat(chosenGas) / Math.pow(10, 8))
                    ).toFixed(8)
                  : (
                      parseFloat(
                        // parseFloat(gasFees?.estimatedBaseFee) +
                        parseFloat(gasFees?.fees?.[customGasFee]?.satPerByte)
                      ) *
                      (parseFloat(gasFees?.transactionSize) / Math.pow(10, 8))
                    ).toFixed(8)
                : 0}{" "}
              {tokenSymbol === "OP" || tokenSymbol === "ARB"
                ? "ETH"
                : tokenSymbol}{" "}
              (
              {gasFees
                ? formatMoney(actions.activeCurrency(fiatFee?.toFixed(3)))
                : 0}
              {currency}) {/* {formatPrice(fiatFee, 3)}$){" "} */}
            </span>
            <TooltipBlackBackground
              hideAfter="3000"
              title=""
              description={
                <>
                  global amount paid for your transaction: <b>base fee</b> +{" "}
                  <b>priority fee</b>
                </>
              }
            />
          </div>
          {/* <div className="gas__fees_adv-time">
            <span>{timeEstimate}</span>
          </div> */}
        </div>
        <div
          className="gas__fees_adv_trigger"
          onClick={() =>
            advanceVisible ? setAdvanceVisible(false) : setAdvanceVisible(true)
          }
        >
          Advance
          <span className="ico icon-chevron-down-solid-1" />
        </div>
      </div>
      {advanceVisible && (
        <div className="gas_fees__adv-bottom">
          <div className="modal_send_rows">
            {/* <div className="gas_fees_label">Priority fee (Gwei)</div> */}
            <div className="gas_fees_options">
              <div className="gas_radios">
                <input
                  type="radio"
                  id="fast"
                  name="gas_speed"
                  defaultValue={"fast"}
                  //defaultValue="high"
                  onChange={handleChange}
                />
                <label htmlFor="fast">
                  Fast
                  <span>
                    {gasFees
                      ? //fees && fees[chosenGas]?.maxFee
                        parseFloat(gasFees?.fees?.fast?.satPerByte)
                      : 0}{" "}
                    {/* GWEI */}
                  </span>
                </label>
              </div>
              <div className="gas_radios">
                <input
                  type="radio"
                  id="standard"
                  name="gas_speed"
                  defaultValue={"standard"}
                  defaultChecked="checked"
                  onChange={handleChange}
                />
                <label htmlFor="standard">
                  Standard
                  <span>
                    {gasFees
                      ? //fees && fees[chosenGas]?.maxFee
                        parseFloat(gasFees?.fees?.standard?.satPerByte)
                      : 0}{" "}
                    {/* GWEI */}
                  </span>
                </label>
              </div>
              <div className="gas_radios">
                <input
                  type="radio"
                  id="slow"
                  name="gas_speed"
                  defaultValue={"slow"}
                  onChange={handleChange}
                />
                <label htmlFor="slow">
                  Slow
                  <span>
                    {gasFees
                      ? //fees && fees[chosenGas]?.maxFee
                        parseFloat(gasFees?.fees?.slow?.satPerByte)
                      : 0}{" "}
                    {/* GWEI */}
                  </span>
                </label>
              </div>
              <div className="gas_radios">
                <input
                  type="radio"
                  id="custom"
                  name="gas_speed"
                  defaultValue="custom"
                  onChange={handleChange}
                />
                <label htmlFor="custom">Custom</label>
              </div>
            </div>
          </div>
          <div className=" modal_send_rows_two_cols">
            {/* <div className="modal_send_rows">
              <div className="form__input form__input_default from_input_send_to">
                <span
                  className={
                    customGasFee === "custom"
                      ? "input_label gas_fees_label"
                      : "input_label gas_fees_label disabled"
                  }
                >
                  Gas Limit
                  <TooltipBlackBackground
                    // title='Lorem ipsum aset dolor sit amet constructeur'
                    description={`maximum amount of gas “>= 21000”`}
                  />
                </span>
                <div className="form__input_default">
                  <input
                    className={
                      customGasFee === "custom"
                        ? `token-amount-input form-input-default ${
                            customGasError ? "!border !border-[#FF0000]" : ""
                          }`
                        : "token-amount-input form-input-default form-input-disabled"
                    }
                    inputMode="decimal"
                    title="Enter Amount"
                    autoComplete="off"
                    autoCorrect="off"
                    type="number"
                    pattern="^[0-9]*[.,]?[0-9]*$"
                    placeholder={customGas}
                    minLength={1}
                    maxLength={6} // !tbd
                    spellCheck="false"
                    onChange={(e) => {
                      setCustomGas(e.target.value);
                      validateCustomGas(e.target.value);
                    }}
                    value={customGas}
                  />
                  {customGasError && (
                    <span className="pt-16 text-red-500 text-sm">
                      {customGasError}
                    </span>
                  )}
                </div>
              </div>
            </div> */}
            <div className="modal_send_rows">
              <div className="form__input form__input_default from_input_send_to">
                <span
                  className={
                    customGasFee === "custom"
                      ? "input_label gas_fees_label"
                      : "input_label gas_fees_label disabled"
                  }
                >
                  Sats/Byte
                  <TooltipBlackBackground
                    // title='Lorem ipsum aset dolor sit amet constructeur'
                    description={
                      <>
                        prioritise your tx “expressed in <b>Sats/Byte</b>”
                      </>
                    }
                  />
                </span>
                <div className="form__input_default">
                  <input
                    className={
                      customGasFee === "custom"
                        ? `token-amount-input form-input-default ${
                            customPrioFeeError
                              ? "!border !border-[#FF0000]"
                              : ""
                          }`
                        : "token-amount-input form-input-default form-input-disabled"
                    }
                    inputMode="decimal"
                    title="Enter Amount"
                    autoComplete="off"
                    autoCorrect="off"
                    type="number"
                    pattern="^[0-9]*[.,]?[0-9]*$"
                    placeholder={0}
                    minLength={1}
                    maxLength={79}
                    spellCheck="false"
                    // defaultValue=""
                    value={customPrioFee}
                    onChange={(e) => {
                      setCustomPrioFee(e.target.value);
                      validateCustomPrioFee(e.target.value);
                    }}
                  />
                  {customPrioFeeError && (
                    <span className="pt-16 text-red-500 text-sm">
                      {customPrioFeeError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GasFeeOptionsBitcoin;
