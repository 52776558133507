import React, { useRef, useEffect, useState } from "react";
import HSBar from "react-horizontal-stacked-bar-chart";
import { useApp } from "./../../../helpers/AppProvider";

function transformArray(originalArray) {
  const newArray = [];
  let totalWorth = 0;
  const arrayColor = [
    "#A693FD",
    "#F7931A",
    "#2ca02c",
    "#bcbd22",
    "#c49c94",
    "#9edae5",
  ];

  if (originalArray.length > 0) {
    originalArray.forEach((item) => {
      if (item?.value) {
        totalWorth += item?.value;
      }
    });
  }

  // If array has 1 or 2 objects, directly copy them to the new array
  if (originalArray.length <= 2) {
    return originalArray
      .filter((item) => item?.value)
      .map((item, index) => ({
        name: item.name,
        value: item.value,
        description: `${(item.value / totalWorth) * 100}%`,
        color: arrayColor[index] ? arrayColor[index] : null,
      }));
  }

  const itemsOver7Percent = [];
  let combinedValue = 0;

  // Iterate over the original array
  originalArray.forEach((item, index) => {
    // Check if the asset has more than 7% value
    if ((item.value / totalWorth) * 100 > 10) {
      itemsOver7Percent.push({
        name: item.name,
        value: item.value,
        description: `${((item.value / totalWorth) * 100).toFixed(2)}%`,
        color: arrayColor[index] ? arrayColor[index] : null,
      });
    } else {
      combinedValue += item?.value;
    }
  });
  if (combinedValue) {
    itemsOver7Percent.push({
      name: "Others",
      value: combinedValue,
      description: `${((combinedValue / totalWorth) * 100).toFixed(2)}%`,
      color: "#CBD7E9",
    });
  }

  // Check if there are items over 7% value
  if (itemsOver7Percent.length > 0) {
    return itemsOver7Percent;
  }
  return newArray;
}

const TokenStackedGraph = () => {
  const { appData, actions } = useApp();
  const [data, setData] = useState([]);
  // useEffect(() => {
  //   //this method is only calculating the balances of the native coins and it should be fixed
  //   if (appData.activeWallet) {
  //     const activeAddress =
  //       appData?.accounts[appData.activeWallet - 1]?.address; //wallet number
  //     const activeWalletChains =
  //       appData?.portfolioData[activeAddress]?.activeChains;
  //     // const balances = appData?.portfolioData[activeAddress].eth?.balance;
  //     const balances = appData?.portfolioData[activeAddress]?.activeChains.map(
  //       (chain) => {
  //
  //         return {
  //           balance: appData?.portfolioData[activeAddress][chain].balance,
  //           name: chain,
  //         };
  //       }
  //     );
  //      //balances of all chains in the wallet
  //   } else {
  //
  //     //have to make a reduce function to get the total balance of all wallets
  //     const totalBalance = appData?.accounts
  //       ?.map((account) => {
  //
  //         const activeWalletChains =
  //           appData?.portfolioData[account.address]?.activeChains;
  //
  //         const balances = appData?.portfolioData[
  //           account.address
  //         ]?.activeChains.map((chain) => {
  //
  //           return {
  //             balance: parseFloat(
  //               appData?.portfolioData[account.address][chain].balance
  //             ),
  //             name: chain,
  //           };
  //         });
  //         return balances;
  //       })
  //       .reduce((acc, curr) => {
  //         curr.forEach((obj) => {
  //           const existingObj = acc.find((item) => item.name === obj.name);
  //           if (existingObj) {
  //             existingObj.balance += obj.balance;
  //           } else {
  //             acc.push(obj);
  //           }
  //         });
  //         return acc;
  //       }, []);

  //
  //   }
  // }, [appData.activeWallet]);

  useEffect(() => {
    if (!appData?.priceData || !appData?.portfolioData) {
      return;
    }
    let assets;
    if (appData.activeWallet) {
      const AssetsInWallet = appData?.portfolioData[
        appData?.activeWallet
      ]?.activeChains
        ?.filter((chain) => chain !== "avalanche")
        ?.map((chain) => {
          let AssetsInChainInWallet;
          const accountType = appData?.accounts?.find(
            (account) => account?.address === appData?.activeWallet
          )?.type;
          if (accountType === "evm" && chain !== "bitcoin") {
            AssetsInChainInWallet = [
              ...appData?.portfolioData[appData?.activeWallet][chain]?.tokens,
            ];
          }
          AssetsInChainInWallet =
            AssetsInChainInWallet?.length > 0
              ? AssetsInChainInWallet.map((obj) => {
                  return {
                    ...obj,
                    chain: chain,
                    value: parseFloat(
                      appData?.priceData?.filter(
                        (item) => item?.symbol === obj?.symbol
                      )[0]?.price *
                        (parseFloat(obj?.balance) / Math.pow(10, obj?.decimals))
                    ),
                    // ?.toFixed(2),
                    // ?.replace(/\.?0+$/, ""),
                  };
                })
              : [];
          AssetsInChainInWallet.push({
            token_address: null,
            symbol:
              appData?.portfolioData[appData?.activeWallet][chain]?.symbol,
            name: appData?.portfolioData[appData?.activeWallet][chain]
              ?.nativeCurrency,
            logo: appData?.portfolioData[appData?.activeWallet][chain]
              ?.imageURL,
            thumbnail: null,
            decimals: null,
            balance:
              appData?.portfolioData[appData?.activeWallet][chain]?.balance,
            possible_spam: false,
            chain: chain,
            value: parseFloat(
              appData?.priceData?.filter(
                (item) =>
                  item?.symbol ===
                  appData?.portfolioData[appData?.activeWallet][chain]?.symbol
              )[0]?.price *
                parseFloat(
                  appData?.portfolioData[appData?.activeWallet][chain]?.balance
                )
            ),
            // ?.toFixed(2),
            // ?.replace(/\.?0+$/, ""),
          });
          return AssetsInChainInWallet;
        });
      assets = AssetsInWallet?.reduce((acc, obj) => {
        return acc?.concat(obj);
      }, []);
      assets = assets?.filter((item) => {
        if (!isNaN(item?.value)) {
          return {
            ...item,
            description: parseFloat(item?.value)?.toFixed(2),
          };
        }
      });
    } else {
      const AssetsInWallets = appData?.accounts
        // ?.filter((account) => account.type === "evm")
        ?.map((account) => {
          const AssetsInWallet = appData?.portfolioData[
            account?.address
          ]?.activeChains
            ?.filter((chain) => chain !== "avalanche")
            ?.map((chain) => {
              let AssetsInChainInWallet;
              if (account.type === "evm" && chain !== "bitcoin") {
                AssetsInChainInWallet = [
                  ...appData?.portfolioData[account?.address][chain]?.tokens,
                ];
              }
              AssetsInChainInWallet =
                AssetsInChainInWallet?.length > 0
                  ? AssetsInChainInWallet.map((obj) => {
                      return {
                        ...obj,
                        chain: chain,
                        value: parseFloat(
                          appData?.priceData?.filter(
                            (item) => item?.symbol === obj?.symbol
                          )[0]?.price *
                            (parseFloat(obj?.balance) /
                              Math.pow(10, obj?.decimals))
                        ),
                        // ?.toFixed(2),
                        // ?.replace(/\.?0+$/, ""),
                      };
                    })
                  : [];
              AssetsInChainInWallet.push({
                token_address: null,
                symbol: appData?.portfolioData[account?.address][chain]?.symbol,
                name: appData?.portfolioData[account?.address][chain]
                  ?.nativeCurrency,
                logo: appData?.portfolioData[account?.address][chain]?.imageURL,
                thumbnail: null,
                decimals: null,
                balance:
                  appData?.portfolioData[account?.address][chain]?.balance,
                possible_spam: false,
                chain: chain,
                value: parseFloat(
                  appData?.priceData?.filter(
                    (item) =>
                      item?.symbol ===
                      appData?.portfolioData[account?.address][chain]?.symbol
                  )[0]?.price *
                    parseFloat(
                      appData?.portfolioData[account?.address][chain]?.balance
                    )
                ),
                // ?.toFixed(2),
                // ?.replace(/\.?0+$/, ""),
              });
              return AssetsInChainInWallet;
            });
          return AssetsInWallet?.reduce((acc, obj) => {
            return acc?.concat(obj);
          }, []);
        });

      let unfilteredAssets =
        AssetsInWallets?.length > 0
          ? AssetsInWallets?.reduce((acc, obj) => {
              return acc?.concat(obj);
            })
          : [];

      unfilteredAssets =
        unfilteredAssets?.length > 0
          ? Object.values(
              unfilteredAssets.reduce((acc, token) => {
                const key = `${token?.name}-${token?.symbol}-${token?.chain}`;
                if (!acc[key]) acc[key] = { ...token };
                else {
                  acc[key].balance = (
                    parseFloat(acc[key]?.balance) + parseFloat(token?.balance)
                  ).toString();
                  acc[key].value =
                    parseFloat(acc[key]?.value) + parseFloat(token?.value);
                }
                return acc;
              }, {})
            ).map((obj) => ({ ...obj }))
          : [];

      assets = unfilteredAssets?.filter((item) => {
        if (!isNaN(item?.value)) {
          return {
            ...item,
            description: parseFloat(item?.value)?.toFixed(2),
          };
        }
      });

      // setData(assets);
    }

    const uniqueTokensMap = new Map();

    // Iterate through the array and update the balance for duplicates
    assets?.forEach((token) => {
      const key = `${token.symbol}`;
      if (uniqueTokensMap.has(key)) {
        // Update the balance for duplicates
        uniqueTokensMap.get(key).balance += parseFloat(token?.balance);
        uniqueTokensMap.get(key).value += parseFloat(token?.value);
      } else {
        // Add a new entry for unique tokens
        uniqueTokensMap.set(key, {
          ...token,
          balance: parseFloat(token?.balance),
          value: parseFloat(token?.value),
        });
      }
    });

    // Convert the Map values back to an array
    assets = Array.from(uniqueTokensMap.values());

    assets?.sort((a, b) => b?.value - a?.value);

    setData(transformArray(assets.filter((item) => !item?.possible_spam)));

    // if (assets?.length > 2) {
    //   if (
    //     assets[0]?.value > 0 &&
    //     assets[1]?.value > 0 &&
    //     assets[2]?.value > 0
    //   ) {
    //     let totalBalance = 0;
    //     for (let i = 2; i < assets.length; i++) {
    //       totalBalance += assets[i]?.value;
    //     }
    //     setData([
    //       {
    //         name: assets[0]?.name,
    //         value: parseFloat(assets[0]?.value?.toFixed(2)),
    //         description:
    //           (
    //             (parseFloat(assets[0]?.value?.toFixed(2)) /
    //               (totalBalance +
    //                 parseFloat(assets[0]?.value?.toFixed(2)) +
    //                 parseFloat(assets[1]?.value?.toFixed(2)))) *
    //             100
    //           )
    //             ?.toFixed(2)
    //             ?.toString() + "%",

    //         color: "#F7931A",
    //       },
    //       {
    //         name: assets[1]?.name,
    //         value: parseFloat(assets[1]?.value?.toFixed(2)),
    //         description:
    //           (
    //             (parseFloat(assets[1]?.value?.toFixed(2)) /
    //               (totalBalance +
    //                 parseFloat(assets[0]?.value?.toFixed(2)) +
    //                 parseFloat(assets[1]?.value?.toFixed(2)))) *
    //             100
    //           )
    //             ?.toFixed(2)
    //             ?.toString() + "%",
    //         color:"#F7931A", "#A693FD",
    //       },
    //       {
    //         name: "Others",
    //         value: parseFloat(parseFloat(totalBalance)?.toFixed(2)),
    //         description:
    //           (
    //             (parseFloat(parseFloat(totalBalance)?.toFixed(2)) /
    //               (totalBalance +
    //                 parseFloat(assets[0]?.value?.toFixed(2)) +
    //                 parseFloat(assets[1]?.value?.toFixed(2)))) *
    //             100
    //           )
    //             ?.toFixed(2)
    //             ?.toString() + "%",
    //         color: "#F7931A", "#A693FD","#CBD7E9",
    //       },
    //     ]);
    //   } else if (
    //     assets[0]?.value > 0 &&
    //     assets[1]?.value > 0 &&
    //     assets[2]?.value > 0
    //   ) {
    //     setData([
    //       {
    //         name: assets[0]?.name,
    //         value: parseFloat(assets[0]?.value?.toFixed(2)),
    //         description:
    //           (
    //             (parseFloat(assets[0]?.value?.toFixed(2)) /
    //               (parseFloat(assets[0]?.value?.toFixed(2)) +
    //                 parseFloat(assets[1]?.value?.toFixed(2)))) *
    //             100
    //           )
    //             ?.toFixed(2)
    //             ?.toString() + "%",

    //         color: "#F7931A",
    //       },
    //       {
    //         name: assets[1]?.name,
    //         value: parseFloat(assets[1]?.value?.toFixed(2)),
    //         description:
    //           (
    //             (parseFloat(assets[1]?.value?.toFixed(2)) /
    //               (parseFloat(assets[0]?.value?.toFixed(2)) +
    //                 parseFloat(assets[1]?.value?.toFixed(2)))) *
    //             100
    //           )
    //             ?.toFixed(2)
    //             ?.toString() + "%",
    //         color: "#A693FD",
    //       },
    //     ]);
    //   } else if (assets[0]?.value > 0 && assets[1]?.value === 0) {
    //     setData([
    //       {
    //         name: assets[0]?.name,
    //         value: parseFloat(assets[0]?.value?.toFixed(2)),
    //         // description: "100%",
    //         color: "#CBD7E9",
    //       },
    //     ]);
    //   } else {
    //     setData([
    //       { name: "No Assets", value: 0, description: "0%", color: "#CBD7E9" },
    //     ]);
    //   }
    // } else {
    //   setData([
    //     {
    //       name: assets && assets?.length > 0 ? assets[0]?.name : null,
    //       value:
    //         assets && assets?.length > 0
    //           ? parseFloat(assets[0]?.value?.toFixed(2))
    //           : 0,
    //     },
    //     {
    //       name: assets && assets?.length > 0 ? assets[1]?.name : null,
    //       value:
    //         assets && assets?.length > 0
    //           ? parseFloat(assets[1]?.value?.toFixed(2))
    //           : 0,
    //     },
    //   ]);
    // }
  }, [appData.priceData, appData.portfolioData, appData.activeWallet]);

  return <HSBar showTextDown data={data} />;
};
export default TokenStackedGraph;
