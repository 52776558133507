import React, { useEffect, useState } from "react";
import TokenIcon from "../../common/TokenIcon/TokenIcon";
import AssetBadge from "../../common/AssetBadge/AssetBadge";
// import PercentWorthGained from "../PercentWorthGained/PercentWorthGained";
import WalletItemControls from "../WalletItemControls/WalletItemControls";
import TooltipCopy from "./../../../components/registration/TooltipCopy";
// import DuplicateIcon from "@heroicons/react/outline/DuplicateIcon";
// import TrashIcon from "@heroicons/react/outline/TrashIcon";
import PencilIcon from "@heroicons/react/solid/PencilIcon";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  // EXPORT_WALLET_PRIVATE_KEY_FLOW,
  REMOVE_WALLET_FLOW,
  // DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
// import RemoveWalletProcess from "../../common/RemoveWalletProcess/RemoveWalletProcess";

// import {
//   copyToClipboard,
//   middleEllipsis,
// } from "./../../../helpers/generalHelper";
import { useUser } from "./../../../helpers/UserProvider";
import { useApp } from "./../../../helpers/AppProvider";
// import { delay_code } from "./../../../helpers/generalHelper";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../helpers/routes";
// import { checkWalletLinkedToSafleID } from "../../../helpers/walletHelpers";
// import safleIdLinkedIcon from "images/wallets/safleID-linked.svg";

// const safleId = require("@getsafle/safle-identity-wallet").SafleID;

const WalletsListItem = ({
  item,
  openEnterPinModal,
  openEditLabelModal,
  // openQRModal,
  walletLinkedToSafleId,
}) => {
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(REMOVE_WALLET_FLOW);
  const { userData } = useUser();
  // eslint-disable-next-line no-unused-vars
  const [modalData, setModalData] = useModalData();
  const { appData, actions } = useApp();
  const icon = actions.getChainLogo(item.chain);
  const [isSafleWalletEth, setIsSafleWalletEth] = useState(false);
  // const [walletLinkedToSafleId, setWalletLinkedToSafleId] = useState(false);
  //
  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (item) {
  //     checkWalletLinkedtoSafleID(item);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [item]);
  useEffect(() => {
    const safleAddress = appData.hasOwnProperty("safleCard")
      ? appData.safleCard?.address
      : false;

    if (safleAddress && item?.address === safleAddress) {
      setIsSafleWalletEth(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openEditLabelHandler = () => {
    setModalData({
      type: "change_wallet_label",
      address: item.address,
      chainName:
        item?.chain === 1
          ? "ethereum"
          : item?.chain === 137
          ? "polygon"
          : item?.chain === 5
          ? "bsc"
          : "",
    });
    openEditLabelModal();
  };
  // const openQRModalHandler = () => {
  //   setModalData({
  //     type: "qr_code",
  //     chain: item.chain,
  //     address: item.address,
  //     label: item.label,
  //   });
  //   openQRModal();
  // };

  const startRemoveWalletFlow = (item) => {
    setModalData({
      address: item.address,
      // chain: appData.chains.find((i) => i.name === item.chain_name).chain_name,
    });
    setActiveModalNav("modal_remove_wallet_popup");
  };

  const openExportPvKeyHandler = () => {
    setModalData({ type: "export_pvkey", address: item.address });
    openEnterPinModal();
  };

  // const checkWalletLinkedtoSafleID = async (item) => {
  //   const res = await checkIfWalletLinked(item, userData.user.linkedWallets);
  //   setWalletLinkedToSafleId(res);
  //   // res;
  // };

  const tooltipText = "";
  // const tooltipIcon = `${process.env.PUBLIC_URL}/assets/icons/file-icon.svg`;
  const tooltipTextIn = "Copy to clipboard";

  const tooltipTextInChange = "Copied to clipboard";

  const TooltipIconSvg = () => {
    return <span className="icon_copy_gray icon-copy-90"></span>;
  };
  // const TooltipIconSvg2 = () => {
  //   return <span className="icon_copy_white icon-copy-90"></span>;
  // };

  const navigateToDashboard = (e) => {
    e.preventDefault();
    // actions.setAppData({ activeWallet: item.id });
    navigate(ROUTES.DASHBOARD, {
      replace: true,
      state: { activeWallet: item.id },
    });
  };

  const findSafleCardWalletAddr = (addr) => {
    const getSafleCardWallet = appData.wallets.find((item) => {
      return item?.isSafleCard && item?.address === addr;
    });
    return getSafleCardWallet?.address ? true : false;
  };

  const [hide, setHideicon] = useState(true);
  const handleCollaps = () => {
    setHideicon(!hide);
  };

  return (
    <div className="filter_table_tr-row wallet__item--border">
      {item && item.address && (
        <div className="filter_table_tr-inner-row">
          <div
            className="filter_table-row td wallet_item_name flex flex-col items-start"
            data-filter="asset_name"
            style={{ maxWidth: "35%" }}
          >
            <div
              className="asset__coin"
              data-name={
                typeof item?.label === "string"
                  ? item?.label
                  : item?.label?.polygon
              }
              // onClick={() => openQRModalHandler()}
              onClick={(e) => navigateToDashboard(e)}
            >
              {/* <TokenIcon logo={icon ? icon : ""} /> */}
              <div className="asset__coin_names">
                <div className="asset__coin-name wallet__item-name">
                  {typeof item?.label === "string"
                    ? item?.label
                    : item?.label?.polygon}
                </div>
                <AssetBadge
                  coinPrefix={item.type === "bitcoin" ? "BTC" : item.type}
                />
              </div>
            </div>
            <div className="wallet_item_address py-[5px]" data-filter="address">
              <span className="filter_table_address-ctn">
                <span
                  className="filter_table_address-amount"
                  title={item.address}
                >
                  {item.address}
                </span>
                <span className="filter_table_address-copy">
                  {/* <DuplicateIcon /> */}
                  <TooltipCopy
                    extraClasses="tooltip_registration cursor-pointer tooltip_webapp_copy"
                    textToDisplay={tooltipText}
                    imageToDisplay={<TooltipIconSvg />}
                    tooltipTextIn={tooltipTextIn}
                    tooltipTextInChange={tooltipTextInChange}
                    copiedText={item.address}
                    itemProps={item.address}
                    hideAfter="3000"
                  />
                </span>
              </span>
            </div>
            <WalletItemControls
              openExportPvKeyHandler={openExportPvKeyHandler}
              openEditLabelHandler={openEditLabelHandler}
              startRemoveWalletFlow={() => startRemoveWalletFlow(item)}
              isSafleCard={item.isSafleCard}
              isSafleWalletEth={isSafleWalletEth}
            />
          </div>
          {item?.type === "evm" && (
            <div
              className="filter_table-row td wallet_item_address justify-center"
              data-filter="address"
              style={{ maxWidth: "25%" }}
            >
              {hide && (
                <div className="flex flex-row border-[2px] border-[#95a9c0] rounded-[20px] p-[5px] items-center">
                  {appData?.chains?.map((chainName, index) => {
                    return (
                      <img
                        key={index}
                        src={chainName?.details?.imageURL}
                        alt={chainName}
                        className="h-[18px] ml-[10px]"
                      />
                    );
                  })}
                  <div
                    class="filter_table_wallet-tooltiptext collapsible-btn"
                    onClick={handleCollaps}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25px"
                      height="25px"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15.6446 16.6241L10.9309 11.9947L15.6446 7.3653C16.1185 6.89998 16.1185 6.1483 15.6446 5.68298C15.1708 5.21765 14.4055 5.21765 13.9317 5.68298L8.35535 11.1595C7.88155 11.6248 7.88155 12.3765 8.35535 12.8418L13.9317 18.3183C14.4055 18.7836 15.1708 18.7836 15.6446 18.3183C16.1063 17.853 16.1184 17.0894 15.6446 16.6241Z"
                        fill="#090B1A"
                      />
                    </svg>
                  </div>
                </div>
              )}

              {!hide && (
                <div className="flex flex-row border-[2px] border-[#95a9c0] rounded-[20px] p-[5px]">
                  {appData?.chains?.slice(0, 2).map((chainName, index) => {
                    return (
                      <img
                        key={index}
                        src={chainName?.details?.imageURL}
                        alt={chainName}
                        className="h-[25px] ml-[10px]"
                      />
                    );
                  })}
                  <div
                    class="filter_table_wallet-tooltiptext collapsible-btn"
                    onClick={handleCollaps}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15.6446 16.6241L10.9309 11.9947L15.6446 7.3653C16.1185 6.89998 16.1185 6.1483 15.6446 5.68298C15.1708 5.21765 14.4055 5.21765 13.9317 5.68298L8.35535 11.1595C7.88155 11.6248 7.88155 12.3765 8.35535 12.8418L13.9317 18.3183C14.4055 18.7836 15.1708 18.7836 15.6446 18.3183C16.1063 17.853 16.1184 17.0894 15.6446 16.6241Z"
                        fill="#090B1A"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          )}
          {item?.type === "bitcoin" && (
            <div
              className="filter_table-row td wallet_item_address justify-center"
              data-filter="address"
              style={{ maxWidth: "25%" }}
            >
              <img src="images/wallets/currency-bitcoin.svg" alt="" class="h-[36px] w-[36px] mr-[18rem]"></img>
            </div>
          )}
          <div
            className="filter_table-row td wallet_item_controls"
            data-filter="balance"
            style={{ maxWidth: "40%" }}
          >
            <span className="filter_table_wallet_controls">
              <span
                onClick={() => openExportPvKeyHandler()}
                className="filter_table_wallet-icon"
              >
                <span className="filter_table_wallet-tooltiptext export-pkey">
                  Export Wallet{" "}
                </span>
                <img
                  src="images/wallets/wallet-export-dash.png"
                  alt="export-wallet-dash"
                  width="20px"
                />
                {/* <span className="icon-icon-wallet-outline" /> */}
              </span>
              <span
                onClick={() => !item.isSafleCard && openEditLabelHandler()}
                className={`filter_table_wallet-icon`}
                style={{ cursor: item.isSafleCard && "not-allowed" }}
              >
                <span className="filter_table_wallet-tooltiptext">
                  {item.isSafleCard ? "Wallet Cannot be Edited" : "Edit Wallet"}
                </span>
                <PencilIcon
                  className={`${item.isSafleCard && "opacity-20"} `}
                />
              </span>

              <span
                className={`filter_table_wallet-icon disabled !bg-white `}
                //<span className={`filter_table_wallet-icon disabled !bg-white ${(item.isSafleCard || walletLinkedToSafleId )&& 'opacity-50'}`}
                onClick={() =>
                  // !item.isSafleCard &&
                  walletLinkedToSafleId !== item?.address &&
                  startRemoveWalletFlow(item)
                }
              >
                <span
                  className={`filter_table_wallet-tooltiptext  `}
                  //style={{cursor : item.isSafleCard && 'not-allowed'}}
                >
                  {item.isSafleCard || walletLinkedToSafleId === item?.address
                    ? // (item?.chain === 1 &&
                      //   findSafleCardWalletAddr(item?.address)) ||
                      // (item?.chain === 1 &&
                      //   findSafleCardWalletAddr(item?.address)) ||
                      // (item?.chain === 56 &&
                      //   findSafleCardWalletAddr(item?.address)) ||
                      // (item?.chain === 10 &&
                      //   findSafleCardWalletAddr(item?.address)) ||
                      // (item?.chain === 42161 &&
                      //   findSafleCardWalletAddr(item?.address)) ||
                      // (item?.chain === 106 &&
                      //   findSafleCardWalletAddr(item?.address)) ||
                      // (item?.chain === 5000 &&
                      //   findSafleCardWalletAddr(item?.address)) ||
                      // walletLinkedToSafleId
                      "Wallet Cannot be Archived"
                    : "Wallet Archive"}
                </span>
                <img
                  src={"images/wallets/wallet-archive.svg"}
                  width="34px"
                  height="34px"
                  alt="wallet-archive"
                  className={`${
                    (item.isSafleCard ||
                      walletLinkedToSafleId === item?.address) &&
                    // (item?.chain === 1 &&
                    //   findSafleCardWalletAddr(item?.address)) ||
                    // (item?.chain === 56 &&
                    //   findSafleCardWalletAddr(item?.address)) ||
                    // (item?.chain === 10 &&
                    //   findSafleCardWalletAddr(item?.address)) ||
                    // (item?.chain === 42161 &&
                    //   findSafleCardWalletAddr(item?.address)) ||
                    // (item?.chain === 106 &&
                    //   findSafleCardWalletAddr(item?.address)) ||
                    // (item?.chain === 5000 &&
                    //   findSafleCardWalletAddr(item?.address)) ||
                    // walletLinkedToSafleId) &&
                    "opacity-50 cursor-not-allowed "
                  }`}
                />
              </span>

              {/* {item.chain === 1 ||
              item.chain === 10 ||
              item.chain === 56 ||
              item.chain === 42161 ||
              item.chain === 106 ||
              item.chain === 5000 ||
              item?.isSafleCard ? (
                walletLinkedToSafleId || item.isSafleCard ? (
                  <span
                    className="filter_table_wallet-icon disabled !bg-white"
                    onClick={() => checkWalletLinkedtoSafleID(item)}
                  >
                    <span className="filter_table_wallet-tooltiptext">
                      Wallet is linked to SafleID
                    </span>
                    <img
                      src={"images/wallets/safleID-linked.svg"}
                      width="34px"
                      height="34px"
                      alt="safleID-linked"
                    />
                  </span>
                ) : (
                  !walletLinkedToSafleId && (
                    <span
                      className="filter_table_wallet-icon"
                      onClick={() => checkWalletLinkedtoSafleID(item)}
                    >
                      <span className="filter_table_wallet-tooltiptext">
                        Wallet is not linked to SafleID
                      </span>
                      <img
                        src={"images/wallets/safleID-not-linked.svg"}
                        width="34px"
                        height="34px"
                        alt="safle-id-not-linked"
                      />
                    </span>
                  )
                )
              ) : null} */}
            </span>
          </div>
          {/* <div className="filter_table-row td" data-filter="value">
                    <div className="asset__coin-worth-asset">
                        <span className="asset__coin-worth-coin-price mob_only">{ fiateq }</span>
                        <PercentWorthGained hist={ percent } />
                    </div>
                </div> */}
          {/* <div className="filter_table-row td" data-filter="link_safle_id">
                    <span className="link_safle_switch linked modal__trigger" data-target="modal_link_to_safle_id">Linked</span>
                </div> */}
          {/* <WalletItemControls /> */}
        </div>
      )}
    </div>
  );
};

export default WalletsListItem;
